import React from 'react'

import {
  Alert,
  Card, CardHeader, CardBody, CardFooter,
  FormGroup,
  ListGroup, ListGroupItem,
  Modal, ModalBody, ModalFooter,
  Spinner, NavLink,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import { AppContext, } from 'contexts/AppContext'

import CustomSelect from '../../components/CustomSelect'
import { T, ErrAlert, TAlert, TLabel, TInput, TButton } from 'components/TComponents'

import ItvcodeSelector from 'components/ItvcodeSelector'

import ProtectedComponent from 'components/ProtectedComponent'

const maintenanceReducer = (state, action) => {
  switch (action.type) {
  case 'update':
  case 'cancel':
  case 'reprog':
  case 'transform':
  case 'resume':
  case 'done':
    return {
      ...state,
      loading: true,
      status: action.type,
      error: undefined,
      prevent: action.clearPrevent ? undefined : state.prevent
    }
  case 'ready': return {
    status: action.type,
    loading: false,
    baseData: action.payload,
    currentData: { ...action.payload },
    error: undefined,
    prevent: undefined
  }
  case 'prevent': return {
    ...state,
    loading: false,
    status: 'ready',
    prevent: action.payload
  }
  case 'edit': return {
    ...state,
    loading: false,
    currentData: {
      ...state.currentData,
      ...action.payload
    }
  }
  case 'reset': return {
    status: 'ready',
    loading: false,
    baseData: state.baseData,
    currentData: { ...state.baseData },
    error: undefined,
    prevent: state.prevent
  }
  case 'error': return {
    ...state,
    loading: false,
    status: state.status,
    error: action.payload
  }
  default: return state
  }
}

const maintenanceInitialState = {
  status: 'fetch',
  loading: true,
  currentData: undefined,
  baseData: undefined,
  error: undefined,
  prevent: undefined
}

const MaintenancePlanEdit = ({ match, history, location }) => {
  const { api, constants } = React.useContext(AppContext)

  const [maintenancePlanState, maintenanceDispatch] = React.useReducer(maintenanceReducer, maintenanceInitialState)

  const [itvcodesBase, setItvcodesBase] = React.useState([])
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [confirmFinish, setConfirmFinish] = React.useState(false)
  const [confirmCancel, setConfirmCancel] = React.useState(false)
  const [cancelationReasonTemp, setCancelationReasonTemp] = React.useState('')

  const [durationEstimated, setDurationEstimated] = React.useState()

  const {
    masterplanId,

    statusValue,
    userCanceled, cancelationReason,
    businessId, itvcodes,
    userAssignFirstname, userAssignLastname,
    addressLocationLabel,
    nbEquipmentsRemind,

    businessName,
    categoryName,
    familyName,

    datePlanned,
    durationChrono,
    durationDone,
    timeRangeStart,
    timeRangeEnd,
    difficultyId,

    maintenances
  } = maintenancePlanState.currentData || {}

  const hasStatus = React.useCallback((...status) => status.includes(statusValue), [statusValue])

  const canEdit = hasStatus('new', 'scheduled', 'progress') && !loading

  React.useEffect(() => {
    api.get('/masterplans/details', undefined, { masterplanId: match.params.id })
      .then(response => {
        maintenanceDispatch({ type: 'ready', payload: response })
        response.itvcodes.map((codes, i) => {
          return api.get('/itvcodes/list', undefined, {
            ipp: -1,
            businessId: response.businessId,
            familyId: codes.familyId,
            categoryId: codes.categoryId
          })
            .then(response => {
              setItvcodesBase(itvcodesBase => ({
                ...itvcodesBase,
                [i]: response
              }))
            })
            .catch(response => setItvcodesError(response))
            .then(() => setItvcodesLoading(false))
        })
      })
      .catch(response => maintenanceDispatch({ type: 'error', payload: response }))
  }, [api, match])

  React.useEffect(() => {
    if (itvcodes && Object.values(itvcodes).length > 0) {
      {Object.values(itvcodes).map((codes, i) => {
        const body = {
          itvcodes: codes && codes.itvcodes ? codes.itvcodes.map(itvcode => ({
            itvcodeId: itvcode.itvcodeId,
            quantity: itvcode.quantity,
          })) : []
        }
        api.post('/interventions/timeItvcodes', { body: JSON.stringify(body) })
          .then(response => {
            setDurationEstimated(duration => ({
              ...duration,
              [i]: response.durationEstimated
            }))
          })
      })}
    } else {
      setDurationEstimated({})
    }
  }, [api, itvcodes])

  const onItvcodesChange = React.useCallback((itvcodes) => {
    maintenanceDispatch({ type: 'edit', payload: { itvcodes } })
  }  , [])

  const handleChange = React.useCallback(({ target: { name, value } }) =>
    maintenanceDispatch({ type: 'edit', payload: { [name]: value } })
  , [])

  const save = React.useCallback((override) => {
    return api.post('/masterplans/details', {
      _stringifyBody: true,
      body: {
        masterplanId,
        itvcodes: canEdit ? Object.values(maintenancePlanState.currentData.itvcodes).map((codes, i) => ({
          itvcodes: codes && codes.itvcodes ? codes.itvcodes.map(itvcode => ({
            itvcodeId: itvcode.itvcodeId,
            quantity: itvcode.quantity,
            wear: itvcode.wear
          })) : [],
          categoryId:maintenancePlanState.currentData.itvcodes[i].categoryId ,
          familyId: maintenancePlanState.currentData.itvcodes[i].familyId
        })) : undefined,
        difficultyId: canEdit ? maintenancePlanState.currentData.difficultyId : undefined,
        ...override
      }
    })
  }, [api, masterplanId, canEdit, maintenancePlanState.currentData])

  const handleSave = React.useCallback(override => {
    maintenanceDispatch({ type: 'update' })
    Promise.all([
      save(override),
      new Promise(resolve => setTimeout(resolve, 250))
    ])
      .then(([response]) => maintenanceDispatch({ type: 'ready', payload: response }))
      .catch(response =>
        response && response.code === 'preventUpdate'
          ? maintenanceDispatch({ type: 'prevent', payload: { ...response.params, callback: handleSave } })
          : maintenanceDispatch({ type: 'error', payload: response })
      )
      .then(([response]) => maintenanceDispatch({ type: 'ready', payload: response }))
  }, [save])

  const handleSaveAndGoToMaintenance = React.useCallback(override => {
    maintenanceDispatch({ type: 'update' })
    save(override)
      .then(masterplan => {
        api.post('/masterplans/maintenance', {
          _stringifyBody: true,
          body: {
            masterplanId: masterplan.masterplanId,
            itvcodes: masterplan.itvcodes,
            difficultyId: masterplan.difficultyId
          }
        })
          .then(maintenance => history.push(`/maintenance/${maintenance.maintenanceplanId}`))
          .catch(error => maintenanceDispatch({ type: 'error', payload: error }))
      })
      .catch(response => {
        response && response.code === 'preventUpdate'
          ? maintenanceDispatch({ type: 'prevent', payload: { ...response.params, callback: handleSaveAndGoToMaintenance } })
          : maintenanceDispatch({ type: 'error', payload: response })
      })
      .then(([response]) => maintenanceDispatch({ type: 'ready', payload: response }))
  }, [history, save, api])

  const handleCancel = React.useCallback(() => {
    maintenanceDispatch({ type: 'cancel' })

    api.del('/masterplans/details', undefined, { masterplanId: match.params.id, cancelationReason: cancelationReasonTemp })
      .then(response => {
        maintenanceDispatch({ type: 'ready', payload: response })
        setConfirmCancel(false)
      })
      .catch(response => maintenanceDispatch({ type: 'error', payload: response }))
  }, [api, match, cancelationReasonTemp])

  const handleFinish = React.useCallback(() => {
    maintenanceDispatch({ type: 'done' })

    api.post('/masterplans/end', {
      _stringifyBody: true,
      body: {
        masterplanId: match.params.id
      }
    })
      .then(response => {
        maintenanceDispatch({ type: 'ready', payload: response })
        setConfirmFinish(false)
      })
      .catch(response => maintenanceDispatch({ type: 'error', payload: response }))
  }, [api, match])

  const loading = maintenancePlanState.status !== 'ready' || itvcodesLoading

  if (maintenancePlanState.status === 'fetch' && maintenancePlanState.error) {
    return <>
      <NavLink tag={Link} to={{
        pathname: '/maintenanceplan',
        search: location.state ? location.state.maintenance : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <TAlert id="maintenanceNotFound" className="mb-0" color="danger" />
    </>
  }

  return (
    <div className="container-fluid InterventionEdit">
      <NavLink tag={Link} to={{
        pathname: '/maintenanceplan',
        search: location.state ? location.state.maintenance : undefined
      }}>
        <T id="returnToList" />
      </NavLink>
      <Card>
        {/* TODO: loading */}
        {maintenancePlanState.status !== 'fetch' && <CardHeader>
          <div className="ml-2">
            <div className="h4">
              <T id="equipments" />
              <small className="ml-2">{businessName}</small>
              <small className="ml-2">{categoryName}</small>
              <small className="ml-2">{familyName}</small>
            </div>
            <div className="h6 mb-0">
              <span><T id="maintenancePlanId" />{` ${masterplanId}`}</span>
            </div>
          </div>
        </CardHeader>}
        {maintenancePlanState.status === 'fetch' && <CardHeader className="d-flex align-items-center">
          <T id="loading" />
          <Spinner className="ml-2" color="primary" size="sm" />
        </CardHeader>}

        <CardBody>
          <FormGroup tag="fieldset">
            <TLabel className="mb-0" id="statusValue" />
            {maintenancePlanState.status === 'fetch'
              ? <Spinner className="ml-2" color="primary" size="sm" />
              : <T raw id={`maintenanceStatus.${statusValue}`} className="pl-1" />}
          </FormGroup>

          {userAssignFirstname && userAssignLastname && (
            <FormGroup tag="fieldset">
              <TLabel className="mb-0" id="userAssign" />
              {maintenancePlanState.status === 'fetch'
                ? <Spinner className="ml-2" color="primary" size="sm" />
                : <span>{`${userAssignFirstname} ${userAssignLastname}`}</span>}
            </FormGroup>
          )}

          <FormGroup tag="fieldset">
            <TLabel className="mb-0" id="datePlanned.label" />
            {maintenancePlanState.status === 'fetch'
              ? <Spinner className="ml-2" color="primary" size="sm" />
              : <T id="datePlanned.value"
                values={{
                  datePlanned: datePlanned ? new Date(datePlanned) : null,
                  timeStart: timeRangeStart ? new Date().setHours(0, Math.min(timeRangeStart), 0, 0) : undefined,
                  timeEnd: timeRangeStart ? new Date().setHours(0, Math.max(timeRangeEnd), 0, 0) : undefined
                }}
                className="pl-1" />}
          </FormGroup>

          <FormGroup tag="fieldset">
            <TLabel className="mb-0"  id="location" />
            {addressLocationLabel ? (
              <span>{addressLocationLabel}</span>
            ) : (
              <T id="noLocation" />
            )}
          </FormGroup>

          <FormGroup tag="fieldset">
            <TLabel className="mb-0"  id="nbEquipmentsRemind" />
            <span>{nbEquipmentsRemind}</span>
          </FormGroup>

          {userCanceled && <FormGroup tag="fieldset">
            <T id="userCanceled" tagName="p" className="mb-1" values={{ user: userCanceled }} />
            <Alert color="danger">{cancelationReason || <T id="noCancelReason" />}</Alert>
          </FormGroup>}

          {maintenances && maintenances.length > 0 && (
            <FormGroup tag="fieldset">
              <TLabel className="mb-2" id="maintenances.label" />

              <ListGroup>
                {maintenances.map((maintenance, i) => (
                  <ListGroupItem key={maintenance.maintenanceplanId}>
                    <Link key={i} tag={Link} to={{
                      pathname: `/maintenance/${maintenance.maintenanceplanId}`,
                    }}>
                      <span className="d-block"><T id="maintenanceId" />{` ${maintenance.maintenanceplanId} `}</span>
                      <span className="d-block"><T id="maintenanceStatus" /><T raw id={`maintenanceStatus.${maintenance.statusValue}`} className="pl-1" /></span>
                      {maintenance.datePlanned && (
                        <span className="d-block">
                          <T id="datePlanned.label" />
                          <T id="datePlanned.value"
                            values={{
                              datePlanned: maintenance.datePlanned ? new Date(maintenance.datePlanned) : null
                            }}
                            className="pl-1" />
                        </span>
                      )}
                      {maintenance.userIdAssignedTo && (
                        <span className="d-block">
                          <T id="technician" />
                          <span>{`${maintenance.firstName} ${maintenance.lastName}`}</span>
                        </span>
                      )}
                    </Link>
                  </ListGroupItem>
                )
                )}
              </ListGroup>
            </FormGroup>
          )}

          {itvcodes && itvcodes.length > 0 && itvcodes.map((codes, i) => (
            <>
              <TLabel id="parcSelected" />
              <ListGroup className="mb-3">
                <ListGroupItem className="d-flex align-items-center">
                  <T id="nbEquipments"
                    values={{
                      businessName: maintenancePlanState.currentData.businessName,
                      categoryName: codes.categoryName,
                      familyName: codes.familyName
                    }} />
                </ListGroupItem>
              </ListGroup>
              <TLabel id="itvcodeList" />
              {itvcodesError ? <ErrAlert error={itvcodesError} /> : itvcodesBase && itvcodesBase[i] && codes && (
                <ItvcodeSelector
                  hasBusiness={businessId}
                  loading={maintenancePlanState.status === 'fetch' || itvcodesLoading}
                  itvcodesAdded={codes.itvcodes}
                  itvcodes={itvcodesBase[i].result}
                  reason={false}
                  noWear
                  onChange={codes => onItvcodesChange(itvcodes.map((codeList, j) => {
                    if (i === j) {
                      codeList.itvcodes = codes
                    }
                    return codeList
                  }))}                  
                />
              )}

              <TLabel id="durations" />
              <Card className="mb-4">
                <CardBody className="pb-3">
                  <div className="row mb-2">
                    {/* Display /60 hour */}
                    <TLabel
                      id="durationEstimated"
                      values={{
                        hours: Math.floor(durationEstimated[i] / 60),
                        minutes: durationEstimated[i] % 60,
                      }}
                      className="col mb-0" />
                  </div>
                  {(durationChrono != null || durationDone != null) && <div className="row mb-2">
                    {durationChrono != null && <TLabel
                      id="durationChrono"
                      values={{
                        hours: Math.floor(durationChrono / 60),
                        minutes: durationChrono % 60,
                      }}
                      className="col mb-0" />}
                    {durationDone != null && <TLabel
                      id="durationDone"
                      values={{
                        hours: Math.floor(durationDone / 60),
                        minutes: durationDone % 60,
                      }}
                      className="col mb-0" />}
                  </div>}
                </CardBody>
              </Card>
            </>
          ))}

          <FormGroup tag="fieldset" className="mt-3">
            <TLabel id="difficultyId" for="difficultyId" />
            <div className={`difficulty ${!loading && difficultyId && constants.difficultyLevels.find(d => Number(d.value) === Number(difficultyId)).key}`}>
              <CustomSelect
                inputId="difficultyId"
                name="difficultyId"
                options={constants.difficultyLevels}
                onChange={e => { handleChange({ target: { name: 'difficultyId', value: e.value } }) }}
                value={constants.difficultyLevels.filter(d => d.value === difficultyId)}
                isDisabled={!canEdit}
                getOptionLabel={option => <T id={`difficulty.${option.key}`} raw />}
                getOptionValue={option => option.value} />
            </div>
          </FormGroup>
        </CardBody>

        <CardFooter>
          {maintenancePlanState.status !== 'cancel' && maintenancePlanState.error && <ErrAlert error={maintenancePlanState.error} values={{ value: maintenancePlanState.error.itvcodes && maintenancePlanState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
          <div className="d-flex">
            {!hasStatus('canceled', 'done') && (
              <ProtectedComponent rights={['masterplan-cancel']}>
                <TButton disabled={maintenancePlanState.loading}
                  onClick={() => setConfirmCancel(true)} color="danger"
                  className="mx-1"
                  id="cancel" />
              </ProtectedComponent>
            )}
            <div className="ml-auto">
              {!hasStatus('canceled', 'done') && (
                <ProtectedComponent rights={['masterplan-edit']}>
                  <TButton disabled={maintenancePlanState.loading}
                    spin={(maintenancePlanState.loading)}
                    className="mx-1"
                    onClick={() => handleSave()}
                    id="save" />
                </ProtectedComponent>
              )}
              {!hasStatus('canceled', 'done') && (
                <ProtectedComponent rights={['masterplan-edit']}>
                  <TButton disabled={maintenancePlanState.loading || !hasStatus('new', 'scheduled', 'progress')}
                    spin={(maintenancePlanState.loading)}
                    className="mx-1"
                    onClick={() => handleSaveAndGoToMaintenance()}
                    id="saveAndGoToMaintenance" />
                </ProtectedComponent>
              )}
              {!hasStatus('canceled', 'done') && (
                <ProtectedComponent rights={['masterplan-done']}>
                  <TButton disabled={maintenancePlanState.loading || !hasStatus('new', 'scheduled', 'progress')}
                    spin={(maintenancePlanState.loading)}
                    className="mx-1"
                    onClick={() => setConfirmFinish(true)}
                    id="finish" />
                </ProtectedComponent>
              )}
            </div>
          </div>
        </CardFooter>
      </Card>

      {maintenancePlanState.prevent && <Modal isOpen={true} fade={true} toggle={() => maintenanceDispatch({ type: 'prevent', payload: undefined })}>
        <ModalBody>
          <T id="modal.preventUpdate.content" values={maintenancePlanState.prevent} />

          <ListGroup>
            {maintenancePlanState.prevent.reasons && maintenancePlanState.prevent.reasons.map(reason => (
              <ListGroupItem key={reason}><T id={`modal.preventUpdate.reason.${reason}`} values={maintenancePlanState.prevent} /></ListGroupItem>
            ))}
          </ListGroup>
        </ModalBody>
        <ModalFooter>
          <TButton disabled={maintenancePlanState.loading || maintenancePlanState.status === 'cancel'}
            onClick={() => maintenanceDispatch({ type: 'prevent', })}
            id="modal.preventUpdate.cancel" />
          <TButton disabled={maintenancePlanState.loading || maintenancePlanState.status === 'cancel'} spin={maintenancePlanState.loading || maintenancePlanState.status === 'cancel'}
            className="ml-2" color="danger" onClick={() => maintenancePlanState.prevent.callback({
              force: maintenancePlanState.prevent.reasons
            })}
            id="modal.preventUpdate.confirm" />
        </ModalFooter>
      </Modal>
      }

      <Modal isOpen={confirmCancel} fade={true} toggle={() => setConfirmCancel(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.cancelContent" /></div>
          <TLabel for="cancelationReason" id="modal.cancelationReason" />
          <TInput id="cancelationReason"
            type="textarea" name="cancelationReasonTemp"
            value={cancelationReasonTemp}
            onChange={e => setCancelationReasonTemp(e.target.value)}
            placeholder="modal.cancelationReasonPlaceholder" />
          {maintenancePlanState.status === 'cancel' && maintenancePlanState.error && <ErrAlert className="mb-0 mt-2" error={maintenancePlanState.error} values={{ value: maintenancePlanState.error.itvcodes && maintenancePlanState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
        </ModalBody>
        <ModalFooter>
          <TButton disabled={maintenancePlanState.loading && maintenancePlanState.status === 'cancel'}
            onClick={() => setConfirmCancel(false)}
            id="modal.cancelCancel" />
          <TButton disabled={maintenancePlanState.loading && maintenancePlanState.status === 'cancel'} spin={maintenancePlanState.loading && maintenancePlanState.status === 'cancel'}
            className="ml-2" color="danger" onClick={handleCancel}
            id="modal.cancelConfirm" />
        </ModalFooter>
      </Modal>

      <Modal isOpen={confirmFinish} fade={true} toggle={() => setConfirmFinish(false)}>
        <ModalBody>
          <div className="mb-1"><T id="modal.finishContent" /></div>
          {maintenancePlanState.status === 'done' && maintenancePlanState.error && <ErrAlert className="mb-0 mt-2" error={maintenancePlanState.error} values={{ value: maintenancePlanState.error.itvcodes && maintenancePlanState.error.itvcodes.map((code, i) => i === 0 ? code : `, ${code}`) }} />}
        </ModalBody>
        <ModalFooter>
          <TButton disabled={maintenancePlanState.loading && maintenancePlanState.status === 'done'}
            onClick={() => setConfirmFinish(false)}
            id="modal.finishCancel" />
          <TButton disabled={maintenancePlanState.loading && maintenancePlanState.status === 'done'} spin={maintenancePlanState.loading && maintenancePlanState.status === 'done'}
            className="ml-2" color="danger" onClick={handleFinish}
            id="modal.finishConfirm" />
        </ModalFooter>
      </Modal>
    </div >
  )
}

export default MaintenancePlanEdit
